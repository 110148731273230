const ApiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000/';
class AuthServiceProvider {
  #token = null;
  #user = null;

  constructor() {
    let token = localStorage.getItem('auth_token');
    if(token) {

      let rawData = localStorage.getItem('auth_user');
      if(!rawData) { return; }
      let userData  = JSON.parse(rawData)
      if(!userData.role) { console.error('localStorage data is not valid'); return; }
      this.#token = token;
      if(userData) { this.#user = userData; }

    }
  }

  getToken() {
    return this.#token;
  }

  getUserData(field) {
    if(this.#user === null) { return null; }
    return typeof this.#user[field] === 'undefined'?null:this.#user[field];
  }

  getUserId() {
    if(!this.isAuthorized()) { return null; }
    return this.getUserData('user_id');
  }

  getCustomerId() {
    if(!this.isAuthorized()) { return null; }
    return this.getUserData('customer_id');
  }

  getUserRole() {
    if(!this.isAuthorized()) { return null; }
    return this.getUserData('role');
  }

  authorize(token,userData) {
    console.info("Authorize inside AuthService");
    this.#token = token;
    localStorage.setItem('auth_token',token);
    if(userData) {
      this.#user = userData;
      localStorage.setItem('auth_user',JSON.stringify(userData));
    }
  }

  signOut() {}

  isAuthorized() {
    return this.#token!==null;
  }
  async logOut() {
    this.#token = null;
    this.#user = null;
    localStorage.removeItem('auth_token');
    localStorage.removeItem('auth_user');
    //todo: destroy the token
  }

  isLoginRequired() {
    return !this.isAuthorized();
  }

  async signIn(login,password) {
    return fetch(ApiBaseUrl+'/auth',{
      method:'POST',
      headers: {
        'Content-Type':'application/json',
        'Accept':'application/json',
      },
      body: JSON.stringify({username:login,password: password})
    }).then(res=>{
      console.log('hir');
      if(!res.ok) {
        console.error('REST response status '+res.status+': '+res.statusText);
        return {status:false,error:'Server error'}
      } else {
        return res.json();
      }
    }).then(response=>{
      console.log('got some response!');
      if(response.status && response.data.result) {
          AuthService.authorize(response.data.token,response.data);
          return response.data;
      } else {
          return new Error('failed login');
      }
    });
  }
}

export const AuthService = new AuthServiceProvider();
