/* eslint-disable global-require */
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { themeColorStorageKey } from './constants/defaultValues';

const defaultColor = 'light.purplemonster';

localStorage.setItem(themeColorStorageKey, defaultColor);

const render = () => {
  import(`./assets/css/sass/themes/gogo.${defaultColor}.scss`).then(() => {
    require('./AppRenderer');
  });
};

render();

// We handle the messages on src/components/common/HandleWindowMessages.js
// but we need to handle the LOGIN once before the app is loaded
// Please, never remove this code @ramon
window.onmessage = (e) => {
  try {
    const [action, message] = e.data.split("---WOW---");
    switch (action) {
      case "LOGIN":
        console.info(`LOGIN message from parent window`);
        const parsed = JSON.parse(message);
        localStorage.setItem('auth_user', JSON.stringify(parsed));
        localStorage.setItem('auth_token', parsed.token);
        break;
    }
  } catch (error) {
    console.error(error);
  }
};
